import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
    const data = useStaticQuery(graphql`
    query {
      heroimage: file(relativePath: { eq: "clientx-all.png" }) {
        childImageSharp {
          fluid(maxWidth: 1540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)
    return (
        <div className="hero-applications">
            <div className="container">
            <div className='column'>
                <div>
                <div className="main-text"> Client <span>X.</span></div>
                <div className="sub-text">  The application for managing the process.   </div>
                </div>
                <div className = 'sideimage'> 
                  <Img className = 'graphic' fluid={data.heroimage.childImageSharp.fluid} />
                </div>
                <div className='child'>
                    <h3> Data-driven efficiency. </h3>
                    <p>
                    Our algorithms use all relevant information about the city infrastructure, travel restrictions, 
                    driver preferences and crowdedness. They work for any vehicle: traditional waste collection trucks, 
                    electric vehicles, bikes and more. 
                    </p>
                </div>
                <div className='child right'>
                    <h3> Actionable insights. </h3>
                    <p>
                    Realtime and historic information about the full process. Follow the drivers and analyse their progress. 
                    Use our machine learning engine for predicting the expected waste amounts and types to always be prepared 
                    for tomorrow. 
                    </p>
                </div>
                <div className='child'>
                    <h3> A client management system that saves you time. </h3>
                    <p>
                    Manage client contracts, product lists and possible service requests. A fully integrated invoicing 
                    system allows to automatically incorporate all client requests. Set up rules to automatically solve 
                    customer requests.
                    </p>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Hero

