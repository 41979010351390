import React from "react"
import "../styles/styles.scss"

import Header from "../components-en/header"
import Demo from "../components-en/demo"
import Footer from "../components-en/footer"

import Hero from "../clientx/hero-en"
import Features from "../clientx/features-en"

import {Helmet} from 'react-helmet'


const IndexPage = () => (
    <div>
      <Helmet>
        <html lang="en" />
        <title>Skialabs - ClientX. </title>
        <description>ClientX - the app for the administration.</description>
      </Helmet>
      <Header />
      <Hero />
      <Features />
      <Demo />
      <Footer />
    </div>
)

export default IndexPage
