import React from "react"
import { FaRoute, FaSortAmountUp, FaAddressCard} from "react-icons/fa"

const Features = () => {
    return(
        <div className="features-applications">
        <div className="container">
            <div className="row-feat header">
                <div className="column-feat header">
                <div className="main-text"> Features </div>
                <div className="sub-text">  Select the components that fit your processes.  </div>
                </div>
            </div>
            <div className="row-feat">
                <div className="column-feat">
                    <div className="title">  Predict.</div>
                    <div> <FaRoute size={50} color={"#7FACD6"}/></div>
                    <div>Machine learning engine for predicting the expected waste streams. </div>
                    <div>Historical analysis of the driven routes, made requests and collected waste. </div>
                    <div>Fast data-driven insights to optimize fleet use. </div>
                </div>
                <div className="column-feat">
                    <div className="title">  Optimize.</div>
                    <div> <FaSortAmountUp size={50} color={"#7FACD6"}/> </div>
                    <div>Optimal for all vehicles, waste types and container types.   </div>
                    <div>Various optimization options: clusters, minimize travel time, minimize used vehicles.  </div>
                    <div>Dynamically adapt routes during the day. </div>
                </div>
                <div className="column-feat">
                    <div className="title">Manage.</div>
                    <div> <FaAddressCard size={50} color={"#7FACD6"}/> </div>
                    <div>Process monitor: follow the real-time progress during the day.   </div>
                    <div>Resolve client and driver requests manually or automatically.</div>
                    <div>Full customer management solution and invoicing. </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Features